import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Building a Planning Poker App`}</h1>
    <p>{`A few months ago, I built `}<a parentName="p" {...{
        "href": "https://ep-planning-poker.fly.dev/"
      }}>{`a planning poker app`}</a>{` that teams in my organization have been using to estimate JIRA tickets.`}</p>
    <h2><HashLink id="why" to="/planning-poker#why" mdxType="HashLink">{`Why?`}</HashLink></h2>
    <p>{`I built the app for a few reasons.`}</p>
    <h3><HashLink id="estimate" to="/planning-poker#estimate" mdxType="HashLink">{`We needed to estimate tickets`}</HashLink></h3>
    <p>{`First and foremost, our team didn't have a reliable tool for this. We were using Hatjitsu for a while but started seeing some performance issues and bugs where we'd need to create a new room every time we estimated. We tried out other apps but the good ones eventually put up a pay wall.`}</p>
    <h3><HashLink id="learn" to="/planning-poker#learn" mdxType="HashLink">{`Learning opportunity`}</HashLink></h3>
    <p>{`I wanted to learn more about Remix.run and web sockets but I was waiting to build something until I could build something practical. This project seemed like a great fit.`}</p>
    <h3><HashLink id="time" to="/planning-poker#time" mdxType="HashLink">{`Time was on my side`}</HashLink></h3>
    <p>{`It's usually not enough to want to learn new tech and have an idea for something to build. You also need the time to learn and build it. Luckily for me, I had the inspiration to build this app on a weekend with nothing going on.`}</p>
    <h2><HashLink id="app" to="/planning-poker#app" mdxType="HashLink">{`The app`}</HashLink></h2>
    <p>{`The application itself is fairly simple.`}</p>
    <p>{`As a user you can either be a voter or a spectator. Spectators can see the board, reveal the current estimates and reset the board. Voters can perform the same actions as a spectator but can also submit estimates.`}</p>
    <p>{`Check out `}<a parentName="p" {...{
        "href": "https://ep-planning-poker.fly.dev"
      }}>{`the production app`}</a>{`.`}</p>
    <p>{`Check out `}<a parentName="p" {...{
        "href": "https://github.com/sean-beard/planning-poker"
      }}>{`the source code`}</a>{`.`}</p>
    <h2><HashLink id="tech" to="/planning-poker#tech" mdxType="HashLink">{`The tech behind the app`}</HashLink></h2>
    <p>{`This application was built using the `}<a parentName="p" {...{
        "href": "https://remix.run/"
      }}>{`Remix`}</a>{` framework which leverages Node.js on the backend and React on the front end.`}</p>
    <h3><HashLink id="server" to="/planning-poker#server" mdxType="HashLink">{`Server side`}</HashLink></h3>
    <p>{`On the server there is a single web socket server instance that clients can connect with.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const wss = new Server({ server });

wss.on('connection', ws => {
  ws.on('message', message => {
    console.log('Received message:\\n\\t %s', message);

    wss.clients.forEach(function each(client) {
      if (client !== ws && client.readyState === WebSocket.OPEN) {
        client.send(message, { binary: false });
      }
    });
  });
});
`}</code></pre>
    <p>{`Every message from a connected client is broadcast to every connected client.`}</p>
    <p>{`This web socket server is only persisted in memory. If the server goes down, all of the web socket connects go down.`}</p>
    <h3><HashLink id="client" to="/planning-poker#client" mdxType="HashLink">{`Client side`}</HashLink></h3>
    <p>{`The client side is where most of the application logic lives. There are two routes, a home page and a page for a "room".`}</p>
    <p>{`From the home page users can create a "room" and navigate to it. A room can be thought of as a distinct group of users connected via their own pool of web socket connections. Users share the unique URL for the room they want their teammates to join.`}</p>
    <p>{`The code for each room establishes a web socket connection for a user, coordinates incoming as well as outgoing socket messaging and manages the majority of the UI state.`}</p>
    <h4><HashLink id="events" to="/planning-poker#events" mdxType="HashLink">{`Event handlers`}</HashLink></h4>
    <p>{`When the page for a room is first mounted the web socket connection is instantiated. There is logic to ping a message every 30 seconds to keep the web socket connection alive (more on this later). Critical event handlers are set including:`}</p>
    <ul>
      <li parentName="ul">{`connecting to the socket`}</li>
      <li parentName="ul">{`receiving messages`}</li>
      <li parentName="ul">{`leaving the room (`}<inlineCode parentName="li">{`beforeunload`}</inlineCode>{` and `}<inlineCode parentName="li">{`onhashchange`}</inlineCode>{` events on the `}<inlineCode parentName="li">{`window`}</inlineCode>{` object)`}</li>
    </ul>
    <h4><HashLink id="data" to="/planning-poker#data" mdxType="HashLink">{`Client data models`}</HashLink></h4>
    <p>{`Clients pass messages to each other with information about players, estimates and other board status updates.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`interface Message {
  userId: string;
  roomId: string;
  isHidden: boolean;
  estimate: number | null;
  isSpectator: boolean;
  reset?: boolean;
  playerLeft?: boolean;
}
`}</code></pre>
    <p>{`These messages are used to manipulate the state of the application and sync state with other clients, including `}<inlineCode parentName="p">{`Player`}</inlineCode>{` state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`interface Player {
  id: string;
  roomId: string;
  isSpectator: boolean;
  estimate: number | null;
}
`}</code></pre>
    <h4><HashLink id="messages" to="/planning-poker#messages" mdxType="HashLink">{`Processing messages`}</HashLink></h4>
    <p>{`The even handler for processing messages is where most of the client-side orchestration happens. One message is processed at a time.`}</p>
    <p>{`Validation is immediately performed on a new message. Messages from other rooms are ignored. In hindsight, this validation should probably happen on the server side.`}</p>
    <p>{`The following state updates are processed depending on the data in the message being received:`}</p>
    <ul>
      <li parentName="ul">{`updating player estimates`}</li>
      <li parentName="ul">{`hiding/showing estimates`}</li>
      <li parentName="ul">{`adding players`}</li>
      <li parentName="ul">{`removing players`}</li>
      <li parentName="ul">{`resetting the board`}</li>
      <li parentName="ul">{`updating spectator status`}</li>
    </ul>
    <h2><HashLink id="improvement" to="/planning-poker#improvement" mdxType="HashLink">{`What could be improved`}</HashLink></h2>
    <p>{`I built this app over a weekend (for the most part). As with any other software solution, there is always room for improvement.`}</p>
    <p>{`Adding automated tests, in general, would be an improvement. I'm guilty of rarely writing tests for my side projects. It's a bad habit that negatively affects me every time I revisit a project after some time.`}</p>
    <p>{`I'm not very confident making changes to a codebase without tests that I haven't touched in a year. The time I spend manually testing would vanish if I just spent a bit of up-front time writing automated tests.`}</p>
    <p>{`Here are some implementation details I think could improve the planning poker app:`}</p>
    <ul>
      <li parentName="ul">{`Extracting logic into smaller files for the room logic would be beneficial for readability and testability`}</li>
      <li parentName="ul">{`Using a state machine to manage all room state permutations would be nice`}</li>
      <li parentName="ul">{`Should probably validate that messages are only sent to their respective room on the server side`}</li>
    </ul>
    <h2><HashLink id="lessons" to="/planning-poker#lessons" mdxType="HashLink">{`The lessons learned`}</HashLink></h2>
    <p>{`I've learned a few things from building and `}<em parentName="p">{`using`}</em>{` this app. Many of my learnings were actually just reinforcements of concepts I was already aware of. But practical use cases like this app help solidify my understanding of some of these concepts.`}</p>
    <h3><HashLink id="hosting" to="/planning-poker#hosting" mdxType="HashLink">{`Hosting matters, especially on a budget`}</HashLink></h3>
    <p>{`Using the app when developing locally isn't the same as using the hosted version. Seems simple, maybe obvious.`}</p>
    <p>{`Your application might behave differently depending on the hosting environment. I originally deployed this app using Heroku. Apps that haven't been visited in a certain amount of time have to "warm up" when using the free pricing tier of Heroku. This resulted in users waiting around 6 seconds before anything loaded when visiting the app for the first time.`}</p>
    <p>{`I've since switched the hosting provider. Now the app is hosted on `}<a parentName="p" {...{
        "href": "https://fly.io/"
      }}>{`Fly.io`}</a>{`. The free tier allows for the app to be accessible instantaneously.`}</p>
    <h3><HashLink id="testing" to="/planning-poker#testing" mdxType="HashLink">{`Works on my machine`}</HashLink></h3>
    <p>{`Testing the app with a single user who is emulating multiple users isn't the same as having multiple players in a practical setting. Seems simple, maybe obvious.`}</p>
    <p>{`For example, did you know that web socket connections close after being idle for 60 seconds? I didn't.`}</p>
    <p>{`The first time my team used this app everyone had to refresh the browser tab before we started voting on a new ticket to re-establish the socket connections. Since we chatted about tickets for more than 60 seconds before voting the socket connections all timed out.`}</p>
    <p>{`I didn't catch this when testing by myself because I was just estimating, resetting the board and estimating again right away.`}</p>
    <h3><HashLink id="remix" to="/planning-poker#remix" mdxType="HashLink">{`Remix gains were slight`}</HashLink></h3>
    <p>{`I learned about the basics of building and deploying a Remix app but I definitely didn't `}<em parentName="p">{`need`}</em>{` Remix for this app.`}</p>
    <p>{`The app uses one data loader to load the room ID parameter but that's it.`}</p>
    <p>{`It was nice to deploy one app instead of a separate back end and front end but managing separate deployments isn't a huge deal. Also, I could've deployed a single app with other frameworks that I'm familiar with (Phoenix, for example).`}</p>
    <p>{`Even though I feel like I've barely scratched the surface with Remix it was still a great learning opportunity.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      